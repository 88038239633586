import { ArrowBackIos } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";


interface BackButtonI{
    float?:boolean;
    to?:string;
}

export function BackButton(props:BackButtonI){
    const {float,to} = props;
    
    let navigate = useNavigate();
    let style:any = {};

    if(typeof float != "undefined" && float)
        style = {position:"absolute"};

    function back(){
        if(to){
            navigate(to);
        }else{
          navigate(-1);
        }
    }

    return <IconButton style={style}  size="small" color="inherit" onClick={back}> <ArrowBackIos /> </IconButton> 
}
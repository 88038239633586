import React from 'react';

import {AppBar, Card, CardContent, Container, Grid} from "@mui/material";
import { Link } from '../ui/Link';
import { Header } from '../ui/Header';
import { DocumentList } from '../ui/DocumentList';

export function Landing() {

  const styles = {
    body:{
      paddingTop:20
    },
    content:{
      paddingLeft:20,
      paddingRight:20
    }
  }


  return <div style={styles.body}>


<Container maxWidth="lg">
<Grid container>
  <Grid item xs={12}>
  <Card>
    <CardContent>
      <div style={styles.content}>
        
      <Grid container spacing={10}>
        <Grid item xs={12} md={4}>
          <div style={{textAlign:"left"}}>
            <h2>Keith Brown</h2>
            <h3>Computer Science &amp;<br/> Electronics Engineering</h3>
            <div>Melbourne, AUS</div><br/>
            <div><Link href="mailto:keith@epidev.com">keith@epidev.com</Link></div>
            <br/>
            <div><Link to="/resume">Resume</Link></div><br/>
         
            <br/>
            <div>I am interested in <br/>Artificial Intelligence, <br/>Computational Metaphysics, <br/>3D Printing and Rock Climbing.</div>
            <br/>
          </div>
        </Grid>
        <Grid item  xs={12} md={8}>
          <h2>Posts</h2>
          <DocumentList src="/post" />
        </Grid>
      </Grid>
  </div>
    </CardContent>
  </Card>



  </Grid>
</Grid>

</Container>

  </div>
}


/*
<h2>Other</h2>
<div><Link to="/recipe">Recipe List</Link></div><br/>
*/